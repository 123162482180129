<template>
  <div>
    <v-progress-linear :active="checking" indeterminate />
    <v-alert
      v-if="checkResults.length === 0"
      outlined
      type="info"
      prominent
      border="left"
    >
      {{ $t("no results found") }}
    </v-alert>
    <v-expansion-panels v-else v-model="resultIdx">
      <template v-for="(result, i) in checkResults">
        <v-expansion-panel :key="i" @click="showOtos = false">
          <v-expansion-panel-header class="title">
            {{ $t(result.provider) }}
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-list rounded v-if="showOtos" dense>
              <v-list-item-group v-model="otoIdx">
                <v-list-item
                  v-for="(oto, j) in otos"
                  active-class="primary white--text"
                  :key="j"
                >
                  <v-list-item-content>
                    <v-list-item-title class="wrap-text">
                      <v-row>
                        <v-col cols="4">
                          <v-chip>{{ oto.oto_id }}</v-chip>
                        </v-col>
                        <v-col cols="8">
                          <template v-for="(v, k, l) in oto">
                            <span
                              :key="l"
                              class="mr-2"
                              small
                              v-if="
                                v != null &&
                                v != '' &&
                                k != 'oto_id' &&
                                k != 'id'
                              "
                            >
                              {{ $t(k) + ": " + v }}
                            </span>
                          </template>
                        </v-col>
                      </v-row>
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </v-list-item-group>
            </v-list>

            <v-list rounded v-else>
              <v-list-item-group v-model="serviceIdx">
                <v-list-item
                  v-for="(service, j) in result.services"
                  active-class="primary white--text"
                  :key="j"
                >
                  <template v-slot:default="{ active }">
                    <v-list-item-content>
                      <v-list-item-title>
                        <v-chip class="mr-4 px-6" large>
                          {{
                            service &&
                            service.name &&
                            service.name in serviceTypes
                              ? serviceTypes[service.name]
                              : service.name
                          }}
                        </v-chip>
                        {{
                          $t("max.") +
                          " " +
                          formatBandwidth(
                            service.maxDownStream || 0,
                            service.maxUpStream || 0
                          )
                        }}
                      </v-list-item-title>
                    </v-list-item-content>
                    <v-list-item-action
                      v-if="active && service.otos.length > 0"
                    >
                      <v-chip @click.stop="showOtos = true" class="px-4" large>
                        {{ service.otos.length }} {{ $t("OTO IDs found") }}
                        <v-icon right>mdi-chevron-down</v-icon>
                      </v-chip>
                    </v-list-item-action>
                  </template>
                </v-list-item>
              </v-list-item-group>
            </v-list>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </template>
    </v-expansion-panels>
  </div>
</template>

<script>
import { createNamespacedHelpers } from "vuex";
import formatBandwidth from "@/utils/mixins/formatBandwidth";

const { mapState, mapGetters, mapMutations, mapActions } =
  createNamespacedHelpers("inetOrder");

export default {
  name: "CheckerResults",
  mixins: [formatBandwidth],
  props: {
    checking: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  data: () => ({
    showOtos: false,
    serviceTypes: {
      5: "EWB (Bern)",
      18: "Zürinet",
      25: "Stadtnetz Luzern",
      26: "St. Galler Stadtwerke",
      29: "Didico (Meilen/Herliberg)",
      30: "Stadtwerke Winterthur",
      52: "Baden4Net",
      58: "Lindau ZH",
      63: "SWL Lenzburg",
      68: "Gemeindebetriebe Muri b. Bern (gbm)",
      94: "Swiss4net",
      BX: "Swisscom (BX)",
      XGS: "Swisscom (XGS-PON)",
      ADSL: "Swisscom (ADSL)",
      VDSL: "Swisscom (VDSL)",
    },
  }),
  computed: {
    ...mapState(["checkResults"]),
    ...mapGetters(["otos", "oto", "isBbcsResult"]),
    resultIdx: {
      ...mapState({ get: "resultIdx" }),
      ...mapActions({ set: "setResultIdx" }),
    },
    serviceIdx: {
      ...mapState({ get: "serviceIdx" }),
      ...mapActions({ set: "setServiceIdx" }),
    },
    otoIdx: {
      ...mapState({ get: "otoIdx" }),
      ...mapActions({ set: "setOtoIdx" }),
    },
  },
  watch: {
    resultIdx() {
      this.showOtos = false;
    },
    serviceIdx() {
      this.showOtos = false;
    },
    checking() {
      this.showOtos = false;
    },
  },
};
</script>

<i18n>
{
  "en": {
    "no results found": "No results found, please go back and change your query!",
    "bbcs": "xDSL Switzerland",
    "bbcs_fiber": "1 Gbit Fiber Switzerland",
    "bbcs_xgspon": "10 Gbit Fiber Switzerland",
    "alex": "Fiber Metro Networks",
    "litex": "Fiber Litecom",
    "litex_xgspon": "10 Gbit Fiber Litecom",
    "max.": "max.",
    "unknown": "unknown",
    "rollout_status": "rollout status",
    "appartment": "appartment",
    "co": "Central Office",
    "fiber_line_state": "fiber line state",
    "oto_state": "OTO state",
    "oto_id": "OTO ID",
    "plug_nr": "Plug number",
    "flat_id": "Flat ID",
    "flat_memo": "Flat memo",
    "cooperation_id": "Cooperation ID",
    "contact_required": "Contact required",
    "OTO IDs found": "OTO IDs found",
    "id": "ID",
    "oto": "OTO",
    "plug": "Plug",
    "allow_ispchange": "allow IPS change",
    "line_state": "line state",
    "active_info": "active info",
    "allow create": "allow create"
  }, 
  "de": {
    "no results found": "Keine Ergebnisse gefunden, bitte gehen Sie zurück und passen Sie Ihre Suche an!",
    "bbcs": "xDSL Schweiz",
    "bbcs_fiber": "1 Gbit Glasfaser Schweiz",
    "bbcs_xgspon": "10 Gbit Glasfaser Switzerland",
    "alex": "Glasfaser Stadtnetze",
    "litex": "Glasfaser Litecom",
    "litex_xgspon": "10 Gbit Glasfaser Litecom",
    "max.": "max.",
    "unknown": "unbekannt",
    "rollout_status": "Ausbaustatus",
    "appartment": "Wohnung",
    "co": "Central Office",
    "fiber_line_state": "Glasfaserstatus",
    "oto_state": "OTO Status",
    "oto_id": "OTO ID",
    "plug_nr": "Plug Nummer",
    "flat_id": "Wohnungs ID",
    "flat_memo": "Wohnungs Notiz",
    "cooperation_id": " Kooperations ID",
    "contact_required": "Kontakt erforderlich",
    "OTO IDs found": "OTO IDs gefunden",
    "id": "ID",
    "oto": "OTO",
    "plug": "Dose",
    "allow_ispchange": "erlaube IPS Wechsel",
    "line_state": "Anschlussstatus",
    "active_info": "aktiv Info",
    "allow create": "erlaube anlegen"
  }
}
</i18n>

<style scoped>
.wrap-text {
  white-space: normal;
  word-wrap: break-word;
  -webkit-line-clamp: unset !important;
}
</style>
